const CLIENTS_LIST_REQUEST = "CLIENTS_LIST_REQUEST";
const CLIENTS_LIST_SUCCESS = "CLIENTS_LIST_SUCCESS";
const CLIENTS_LIST_FAILURE = "CLIENTS_LIST_FAILURE";
const CLIENTS_LIST_RESET = "CLIENTS_LIST_RESET";

const CLIENT_DETAILS_REQUEST = "CLIENT_DETAILS_REQUEST";
const CLIENT_DETAILS_SUCCESS = "CLIENT_DETAILS_SUCCESS";
const CLIENT_DETAILS_FAILURE = "CLIENT_DETAILS_FAILURE";

const CLIENT_CREATE_REQUEST = "CLIENT_CREATE_REQUEST";
const CLIENT_CREATE_SUCCESS = "CLIENT_CREATE_SUCCESS";
const CLIENT_CREATE_FAILURE = "CLIENT_CREATE_FAILURE";
const CLIENT_CREATE_RESET = "CLIENT_CREATE_RESET";

const CLIENT_UPDATE_REQUEST = "CLIENT_UPDATE_REQUEST";
const CLIENT_UPDATE_SUCCESS = "CLIENT_UPDATE_SUCCESS";
const CLIENT_UPDATE_FAILURE = "CLIENT_UPDATE_FAILURE";
const CLIENT_UPDATE_RESET = "CLIENT_UPDATE_RESET";

const CLIENT_DELETE_REQUEST = "CLIENT_DELETE_REQUEST";
const CLIENT_DELETE_SUCCESS = "CLIENT_DELETE_SUCCESS";
const CLIENT_DELETE_FAILURE = "CLIENT_DELETE_FAILURE";
const CLIENT_DELETE_RESET = "CLIENT_DELETE_RESET";

export {
  CLIENTS_LIST_REQUEST,
  CLIENTS_LIST_SUCCESS,
  CLIENTS_LIST_FAILURE,
  CLIENTS_LIST_RESET,
  CLIENT_DETAILS_REQUEST,
  CLIENT_DETAILS_SUCCESS,
  CLIENT_DETAILS_FAILURE,
  CLIENT_CREATE_REQUEST,
  CLIENT_CREATE_SUCCESS,
  CLIENT_CREATE_FAILURE,
  CLIENT_CREATE_RESET,
  CLIENT_UPDATE_REQUEST,
  CLIENT_UPDATE_SUCCESS,
  CLIENT_UPDATE_FAILURE,
  CLIENT_UPDATE_RESET,
  CLIENT_DELETE_REQUEST,
  CLIENT_DELETE_SUCCESS,
  CLIENT_DELETE_FAILURE,
  CLIENT_DELETE_RESET,
};
