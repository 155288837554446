import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  USER_REGISTER_RESET,
  //   USER_DETAILS_REQUEST,
  //   USER_DETAILS_SUCCESS,
  //   USER_DETAILS_FAILURE,
  //   USER_UPDATE_PROFILE_REQUEST,
  //   USER_UPDATE_PROFILE_SUCCESS,
  //   USER_UPDATE_PROFILE_FAILURE,
  //   USER_UPDATE_PROFILE_RESET,
  //   USER_DETAILS_RESET,
  //   USERS_LIST_REQUEST,
  //   USERS_LIST_SUCCESS,
  //   USERS_LIST_FAILURE,
  //   USERS_LIST_RESET,
  //   USER_DELETE_REQUEST,
  //   USER_DELETE_SUCCESS,
  //   USER_DELETE_FAILURE,
  //   USER_UPDATE_REQUEST,
  //   USER_UPDATE_SUCCESS,
  //   USER_UPDATE_FAILURE,
  //   USER_UPDATE_RESET,
} from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAILURE:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return {
        loading: false,
        registerResponse: action.payload,
        success: true,
      };
    case USER_REGISTER_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

// const userDetailsReducer = (state = { user: {} }, action) => {
//   switch (action.type) {
//     case USER_DETAILS_REQUEST:
//       return { ...state, loading: true };
//     case USER_DETAILS_SUCCESS:
//       return {
//         loading: false,
//         user: action.payload,
//       };
//     case USER_DETAILS_FAILURE:
//       return {
//         loading: false,
//         error: action.payload,
//       };
//     case USER_DETAILS_RESET:
//       return {
//         user: {},
//       };
//     default:
//       return state;
//   }
// };

// const userUpdateProfileReducer = (state = {}, action) => {
//   switch (action.type) {
//     case USER_UPDATE_PROFILE_REQUEST:
//       return { loading: true };
//     case USER_UPDATE_PROFILE_SUCCESS:
//       return {
//         loading: false,
//         userInfo: action.payload,
//         success: true,
//       };
//     case USER_UPDATE_PROFILE_FAILURE:
//       return {
//         loading: false,
//         error: action.payload,
//       };
//     case USER_UPDATE_PROFILE_RESET:
//       return {};
//     default:
//       return state;
//   }
// };

// const usersListReducer = (state = { users: [] }, action) => {
//   switch (action.type) {
//     case USERS_LIST_REQUEST:
//       return { loading: true };
//     case USERS_LIST_SUCCESS:
//       return {
//         loading: false,
//         users: action.payload,
//       };
//     case USERS_LIST_FAILURE:
//       return {
//         loading: false,
//         error: action.payload,
//       };
//     case USERS_LIST_RESET:
//       return {
//         users: [],
//       };
//     default:
//       return state;
//   }
// };

// const userDeleteReducer = (state = {}, action) => {
//   // console.log(state, action);
//   switch (action.type) {
//     case USER_DELETE_REQUEST:
//       return { loading: true };
//     case USER_DELETE_SUCCESS:
//       return {
//         loading: false,
//         success: true,
//       };
//     case USER_DELETE_FAILURE:
//       return {
//         loading: false,
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// const userUpdateReducer = (state = { user: {} }, action) => {
//   switch (action.type) {
//     case USER_UPDATE_REQUEST:
//       return { loading: true };
//     case USER_UPDATE_SUCCESS:
//       return {
//         loading: false,
//         success: true,
//       };
//     case USER_UPDATE_FAILURE:
//       return {
//         loading: false,
//         error: action.payload,
//       };
//     case USER_UPDATE_RESET:
//       return { user:{}};
//     default:
//       return state;
//   }
// };

// export {
//   userLoginReducer,
//   userRegisterReducer,
//   userDetailsReducer,
//   userUpdateProfileReducer,
//   usersListReducer,
//   userDeleteReducer,
//   userUpdateReducer,
// };
