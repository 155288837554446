import React, { Component, Suspense } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { CFade } from "@coreui/react";
import "./scss/style.scss";

// Suspense
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  render() {
    const { location } = this.props;
    return (
      <>
        <Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => (
                <CFade key={location.pathname}>
                  <Login {...props} />
                </CFade>
              )}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => (
                <CFade key={location.pathname}>
                  <Register {...props} />
                </CFade>
              )}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </Suspense>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </>
    );
  }
}

export default withRouter(App);
