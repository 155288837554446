import { combineReducers } from "redux";

import { changeState } from "./changeState";
import {
  clientsListReducer,
  clientDetailsReducer,
  clientUpdateReducer,
  clientCreateReducer,
  clientDeleteReducer,
} from "./clientReducers";
import { userLoginReducer, userRegisterReducer } from "./userReducers";

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

export const preloadedState = {
  userLogin: { userInfo: userInfoFromStorage },
};

export const rootReducer = combineReducers({
  changeState,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  clientsList: clientsListReducer,
  clientDetails: clientDetailsReducer,
  clientCreate: clientCreateReducer,
  clientUpdate: clientUpdateReducer,
  clientDelete: clientDeleteReducer,
});
